import { configureStore } from "@reduxjs/toolkit";

import { accountReducer } from "store/slices/account";
import { modalsReducer } from "store/slices/modals";
import { planReducer } from "store/slices/plan";
import { tokenListReducer } from "store/slices/tokenList";

const store = configureStore({
  reducer: {
    modals: modalsReducer,
    account: accountReducer,
    plan: planReducer,
    tokenList: tokenListReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
