import { createAsyncThunk } from "@reduxjs/toolkit";

import { TokenService } from "services/api/token";
import { RootState } from "store";
import { IToken, setTokenListData, setTokenListInitialData, setTotalComputedUnits } from "store/slices/tokenList";

export const loadAccountTokenList = createAsyncThunk<void, void, { state: RootState }>(
  "tokenList/loadAccountTokenList",
  async (_, { dispatch }) => {
    try {
      const { data } = await TokenService.getAccountTokenList();
      const { list } = data;

      const convertedList: IToken[] = list.map((listItem) => {
        return {
          accountId: listItem.account_id,
          computeUnits: listItem.compute_units,
          isActive: listItem.is_active,
          lastUsed: listItem.last_used,
          name: listItem.name,
          requests: listItem.requests,
        };
      });

      const totalComputedUnits = convertedList.reduce((accumulator, current) => accumulator + current.computeUnits, 0);

      dispatch(setTokenListData(convertedList));
      dispatch(setTotalComputedUnits(totalComputedUnits));
    } catch (error) {
      dispatch(setTokenListInitialData());
      console.error("Error while loading account token list data: ", error);
    }
  }
);
