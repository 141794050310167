import { t } from "i18next";
import { FieldErrors } from "react-hook-form";

import { AlertMessageBlock } from "shared/components/AlertMessageBlock";
import { InputApiToken, InputTokenName } from "shared/components/modals/AuthModal/components/inputs";
import { DefaultValuesType } from "shared/components/modals/AuthModal/helpers";

export const CreateToken: React.FC<{
  errors: FieldErrors<DefaultValuesType>;
  apiToken: string;
}> = ({ errors, apiToken }: { errors: FieldErrors<DefaultValuesType>; apiToken: string }) => {
  return (
    <>
      <InputTokenName errors={errors} />
      {apiToken && (
        <>
          <InputApiToken apiToken={apiToken} />
          <AlertMessageBlock text={t("modals.createToken.createdTokenAlert")} />
        </>
      )}
    </>
  );
};
