import { createAsyncThunk } from "@reduxjs/toolkit";
import { t } from "i18next";
import { Dispatch, SetStateAction } from "react";

import { AuthService } from "services/api/auth";
import ToastService from "shared/components/Toast";
import { RootState } from "store";
import { EModals, showModal } from "store/slices/modals";

export const signUp = createAsyncThunk<
  void,
  {
    email: string;
    name: string;
    password: string;
    setLoading: Dispatch<SetStateAction<boolean>>;
    closeHandler: () => void;
    reset: () => void;
  },
  { state: RootState }
>("account/signUp", async ({ email, name, password, setLoading, closeHandler, reset }, { dispatch }) => {
  try {
    setLoading(true);
    const { data } = await AuthService.signUp(email, name, password);
    if (!data.id) return;

    closeHandler();
    dispatch(
      showModal({
        modal: EModals.MESSAGE_MODAL,
        props: {
          title: t("modals.signUpConfirmation.title"),
          description: t("modals.signUpConfirmation.description", { email }),
        },
      })
    );

    reset();
  } catch (error) {
    ToastService.error({ text: t("toast.errors.somethingWentWrong") });
    console.error("Error during signing up: ", error);
  } finally {
    setLoading(false);
  }
});
