import styled from "styled-components";

import { ReactComponent as Error } from "assets/images/icons/error.svg";
import { ReactComponent as Success } from "assets/images/icons/success.svg";
import { flexColumnNoWrap, flexRowNoWrap } from "shared/theme/styles";

const Container = styled.div`
  ${flexRowNoWrap}
  gap: 1rem;
`;

const Description = styled.div<{ $error?: boolean }>`
  ${flexColumnNoWrap}
  gap: 0.5rem;
  align-self: center;

  p {
    :last-child {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 0.938rem;
      color: ${({ theme }) => theme.colors.greyMedium};
    }

    :first-child {
      font-size: 1rem;
      font-weight: 600;
      font-style: normal;
      line-height: 1.375rem;
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const ErrorIcon = styled(Error)`
  width: 2rem;
  height: 2rem;
`;

const SuccessIcon = styled(Success)`
  width: 2rem;
  height: 2rem;
`;

export default {
  SuccessIcon,
  Container,
  Description,
  ErrorIcon,
};
