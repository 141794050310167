import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Dispatch, SetStateAction } from "react";

import { AuthService } from "services/api/auth";
import ToastService from "shared/components/Toast";
import { accountLocalStorage } from "shared/constants";
import { EActionTypes, serverErrorHandler } from "shared/helpers/serverErrorHandler";
import { RootState } from "store";
import { loadAccount } from "store/actions/loadAccount";

export const signIn = createAsyncThunk<
  void,
  {
    email: string;
    password: string;
    setLoading: Dispatch<SetStateAction<boolean>>;
    closeHandler: () => void;
    reset: () => void;
  },
  { state: RootState }
>("account/signIn", async ({ email, password, setLoading, closeHandler, reset }, { dispatch }) => {
  try {
    setLoading(true);
    const { data } = await AuthService.signIn(email, password);
    if (!data) return;

    const jwt = data.jwt;
    localStorage.setItem(accountLocalStorage, jwt);
    dispatch(loadAccount());
    closeHandler();

    reset();
  } catch (error) {
    if (error instanceof AxiosError) {
      ToastService.error({ text: serverErrorHandler(EActionTypes.SIGN_IN, error?.response?.data.code) });
    }
    console.error("Error during signing in: ", error);
  } finally {
    setLoading(false);
  }
});
