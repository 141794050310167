import { useFormContext } from "react-hook-form";

import { ButtonIcon } from "shared/components/Buttons/ButtonIcon";
import InputError from "shared/components/Form/components/Error";
import formStyles from "shared/components/Form/styles";

import { IInputProps } from "./interface";
import styles from "./styles";

export const Input: React.FC<IInputProps> = ({
  id,
  value,
  label,
  error = false,
  errorMessage,
  additionalInputHandler,
  hintHandler,
  ...rest
}: IInputProps) => {
  const { register } = useFormContext();
  const props = rest.disabled ? { value } : { ...register(id) };

  return (
    <formStyles.InputWithError>
      {label && <formStyles.Label>{label}</formStyles.Label>}
      <styles.AdditionalInputHandlerContainer>
        <styles.InputWrapper
          {...rest}
          {...props}
          error={error}
          autoComplete="off"
          autoCorrect="off"
          type={rest.type || "text"}
          onWheel={(event) => event.currentTarget.blur()}
          additionalInputHandler={Boolean(additionalInputHandler)}
        />
        {additionalInputHandler && (
          <ButtonIcon control={{ icon: additionalInputHandler.icon, handler: additionalInputHandler.handler }} />
        )}
      </styles.AdditionalInputHandlerContainer>
      {errorMessage && <InputError text={errorMessage} />}
      {hintHandler && (
        <styles.HintHandler onClick={hintHandler.handler}>
          <styles.Handler>{hintHandler.text}</styles.Handler>
        </styles.HintHandler>
      )}
    </formStyles.InputWithError>
  );
};
