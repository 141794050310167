import styled from "styled-components";

const H1 = styled.h1<{ isCentered?: boolean }>`
  font-size: 3rem;
  font-weight: 700;
  line-height: 130%;
  margin: 0 0 0.5rem 0;
  text-align: ${({ isCentered }) => (isCentered ? "center" : "left")};
  ${({ theme }) => theme.mediaWidth("upToMedium", `font-size: 2rem;`)};
`;

const H2 = styled.h2<{ isCentered?: boolean }>`
  font-weight: 700;
  line-height: 130%;
  font-size: 2.25rem;
  margin: 0 0 3rem 0;
  text-align: ${({ isCentered }) => (isCentered ? "center" : "left")};
  ${({ theme }) => theme.mediaWidth("upToMedium", `margin-bottom: 2rem; font-size: 1.5rem;`)};
  ${({ theme }) => theme.mediaWidth("upToSmall", `margin-bottom: 1.5rem;`)};
`;

const H3 = styled.h3<{ isCentered?: boolean }>`
  font-weight: 700;
  line-height: 140%;
  font-size: 1.5rem;
  margin: 0 0 3rem 0;
  text-align: ${({ isCentered }) => (isCentered ? "center" : "left")};
`;

export default {
  H1,
  H2,
  H3,
};
