import { AxiosResponse } from "axios";

import api from "api";
import { EApiRoutes } from "shared/enum/api-routes";
import { IAccountTokenListResponse, ITokenCreateResponse, ITokenRevokeResponse } from "shared/interfaces";

export class TokenService {
  static async createToken(name: string, accountId: string): Promise<AxiosResponse<ITokenCreateResponse>> {
    return api.post<ITokenCreateResponse>(EApiRoutes.TOKEN, { name, accountId });
  }

  static async revokeToken(name: string, accountId: string): Promise<AxiosResponse<ITokenRevokeResponse>> {
    return api.put<ITokenRevokeResponse>(EApiRoutes.TOKEN_REVOKE, { name, accountId });
  }

  static async getAccountTokenList(): Promise<AxiosResponse<IAccountTokenListResponse>> {
    return api.get<IAccountTokenListResponse>(EApiRoutes.TOKEN, {});
  }
}
