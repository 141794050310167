import { AxiosResponse } from "axios";

import api from "api";
import { EApiRoutes } from "shared/enum/api-routes";
import { IAccountResponse, IPlanResponse } from "shared/interfaces";

export class AccountService {
  static async getAccount(): Promise<AxiosResponse<IAccountResponse>> {
    return api.get<IAccountResponse>(EApiRoutes.ACCOUNT, {});
  }

  static async getPlan(id: string): Promise<AxiosResponse<IPlanResponse>> {
    return api.get<IPlanResponse>(`${EApiRoutes.PLAN}/${id}`, {});
  }
}
