import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as M from "shared/components/modals";
import { IAuthModalProps, IMessageModalProps, IModalProps, IRevokeModalProps } from "shared/interfaces";
import { RootState } from "store";

export enum EModals {
  EMPTY = "EMPTY",
  AUTH_MODAL = "AUTH_MODAL",
  ACCOUNT_MODAL = "ACCOUNT_MODAL",
  MESSAGE_MODAL = "MESSAGE_MODAL",
  REVOKE_MODAL = "REVOKE_MODAL",
}

export type IModalsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [EModals.EMPTY]: any;
  [EModals.AUTH_MODAL]: IAuthModalProps;
  [EModals.ACCOUNT_MODAL]: IModalProps;
  [EModals.MESSAGE_MODAL]: IMessageModalProps;
  [EModals.REVOKE_MODAL]: IRevokeModalProps;
};

export type IModals = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [EModals.EMPTY]: any;
  [EModals.AUTH_MODAL]: React.FC<IAuthModalProps>;
  [EModals.ACCOUNT_MODAL]: React.FC<IModalProps>;
  [EModals.MESSAGE_MODAL]: React.FC<IMessageModalProps>;
  [EModals.REVOKE_MODAL]: React.FC<IRevokeModalProps>;
};

export const MODALS: IModals = {
  [EModals.EMPTY]: null,
  [EModals.AUTH_MODAL]: M.AuthModal,
  [EModals.ACCOUNT_MODAL]: M.AccountModal,
  [EModals.MESSAGE_MODAL]: M.MessageModal,
  [EModals.REVOKE_MODAL]: M.RevokeModal,
};

export type ModalProps<T extends EModals> = Omit<IModalsProps[T], "closeModal">;

interface IModalState {
  modal: EModals;
  props: IModalsProps[EModals];
}

const initialState: IModalState = {
  modal: EModals.EMPTY,
  props: null,
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    showModal: (_, action: PayloadAction<IModalState>) => action.payload,
    closeModal: () => initialState,
  },
});

// SELECTORS
export const selectModalState = (state: RootState) => state.modals;

// ACTIONS
export const { showModal, closeModal } = modalsSlice.actions;

export const modalsReducer = modalsSlice.reducer;
