import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as MainBlockImage } from "assets/images/home/main-block.svg";
import { ReactComponent as ArrowRight } from "assets/images/icons/arrow-right.svg";
import styles from "pages/Home/components/MainBlock/styles";
import { APP_ROUTES } from "routes/appRoutes";
import { defaultVariant } from "shared/components/Animations/landing";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { Translate } from "shared/components/Translate";
import typography from "shared/components/Typography/styles";

export const MainBlock: React.FC = () => {
  const navigate = useNavigate();
  return (
    <styles.MainBlockWrapper variants={defaultVariant} initial="initial" animate="animate">
      <styles.TitleDescription>
        <typography.H1>
          <Translate value="pages.home.mainBlock.title" />
        </typography.H1>
        <styles.Description>
          <Translate value="pages.home.mainBlock.description" />
        </styles.Description>
        <ButtonPrimary
          name={t("actions.startForFree")}
          handler={() => navigate(APP_ROUTES.SIGN_UP)}
          iconRight={<ArrowRight />}
        />
      </styles.TitleDescription>
      <styles.MainBlockImageWrapper>
        <MainBlockImage />
      </styles.MainBlockImageWrapper>
    </styles.MainBlockWrapper>
  );
};
