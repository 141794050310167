import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Dispatch, SetStateAction } from "react";

import { TokenService } from "services/api/token";
import ToastService from "shared/components/Toast";
import { EActionTypes, serverErrorHandler } from "shared/helpers/serverErrorHandler";
import { RootState } from "store";
import { loadAccountTokenList } from "store/actions/loadAccountTokenList";

export const createToken = createAsyncThunk<
  void,
  {
    name: string;
    setLoading: Dispatch<SetStateAction<boolean>>;
    setApiToken: Dispatch<SetStateAction<string>>;
  },
  { state: RootState }
>("token/createToken", async ({ name, setLoading, setApiToken }, { getState, dispatch }) => {
  try {
    setLoading(true);
    const state = getState();
    const accountId = state.account.accountId;
    if (!accountId || !name) return;

    const { data } = await TokenService.createToken(name, accountId);
    if (!data) return;

    const apiToken = data.id;

    setApiToken(apiToken);
    dispatch(loadAccountTokenList());
  } catch (error) {
    if (error instanceof AxiosError) {
      ToastService.error({ text: serverErrorHandler(EActionTypes.TOKEN_CREATE, error?.response?.data.code) });
    }
    console.error("Error during token create: ", error);
  } finally {
    setLoading(false);
  }
});
