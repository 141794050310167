import styled from "styled-components";

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1 0 auto;
`;

export default {
  Container,
  Content,
};
