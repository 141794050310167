import { IControl } from "shared/interfaces";

export enum EButtonColors {
  RED = "RED",
  BLACK = "BLACK",
  TRANSPARENT = "TRANSPARENT",
}

export interface IButtonStatusColors {
  default: string;
  active: string;
  hover: string;
  text: string;
}

export interface IButtonProps {
  name: string;
  handler: () => void;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  isEmpty?: boolean;
  fullWidth?: boolean;
  isCentered?: boolean;
  color?: EButtonColors;
  disabled?: boolean;
}

export interface IButtonIconProps {
  control?: IControl;
}
