import styled from "styled-components";

const ModalControlsHeaderWrapper = styled.div<{ smallIndent: boolean }>`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 6fr 1fr;
  margin-bottom: ${({ smallIndent }) => (smallIndent ? "0.5rem" : "1.5rem")};

  div {
    line-height: 0;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
`;

const Title = styled.span`
  font-weight: 700;
  line-height: 130%;
  font-size: 1.25rem;
  text-align: center;
`;

export default {
  ModalControlsHeaderWrapper,
  Title,
};
