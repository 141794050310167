export const tariffs = [
  {
    name: "tariffs.free.name",
    description: "tariffs.free.description",
    computeResources: "tariffs.free.computeResources",
    requestThroughput: "tariffs.free.requestThroughput",
    price: "tariffs.free.price",
  },
  //// TBA
  // {
  //   name: "tariffs.business.name",
  //   description: "tariffs.business.description",
  //   computeResources: "tariffs.business.computeResources",
  //   requestThroughput: "tariffs.business.requestThroughput",
  //   price: "tariffs.business.price",
  // },
];
