import {
  IForgotPasswordFields,
  IResetPasswordFields,
  ISignInFields,
  ISignUpFields,
} from "shared/components/modals/AuthModal/interface";
import { EMPTY_STRING } from "shared/constants";

export const signUpDefaultValues: ISignUpFields = {
  name: EMPTY_STRING,
  email: EMPTY_STRING,
  password: EMPTY_STRING,
  agreement: false,
};

export const signInDefaultValues: ISignInFields = {
  email: EMPTY_STRING,
  password: EMPTY_STRING,
};

export const forgotPasswordDefaultValues: IForgotPasswordFields = {
  email: EMPTY_STRING,
};

export const resetPasswordDefaultValues: IResetPasswordFields = {
  password: EMPTY_STRING,
};

export const createTokenDefaultValues = {
  name: EMPTY_STRING,
};
