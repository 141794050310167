import { Navigate } from "react-router-dom";

import { APP_ROUTES } from "routes/appRoutes";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { selectAccountData } from "store/slices/account";

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { accountId } = useAppSelector(selectAccountData);
  console.log("accountId", accountId);

  return accountId ? children : <Navigate to={APP_ROUTES.HOME} replace />;
};
