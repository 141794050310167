import { t } from "i18next";

import { ReactComponent as Close } from "assets/images/icons/close.svg";
import { ReactComponent as Logout } from "assets/images/icons/logout.svg";
import { ButtonIcon } from "shared/components/Buttons/ButtonIcon";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { EButtonColors } from "shared/components/Buttons/interfaces";
import styles from "shared/components/modals/AccountModal/styles";
import ModalWrapper from "shared/components/modals/ModalWrapper";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { IModalProps } from "shared/interfaces";
import { signOut } from "store/actions/signOut";
import { selectAccountData } from "store/slices/account";
import { selectPlanData } from "store/slices/plan";

export const AccountModal: React.FC<IModalProps> = ({ closeModal }: IModalProps) => {
  const dispatch = useAppDispatch();
  const { name, email } = useAppSelector(selectAccountData);
  const { name: planName } = useAppSelector(selectPlanData);

  const logoutHandler = () => {
    dispatch(signOut());
    closeModal();
  };

  return (
    <ModalWrapper closeModal={closeModal}>
      <styles.AccountModalHeader>
        <styles.AccountDataWrapper>
          <styles.Account>{name.substring(0, 1)}</styles.Account>
          <styles.NameEmailWrapper>
            <styles.Name>{name}</styles.Name>
            <styles.Email>{email}</styles.Email>
          </styles.NameEmailWrapper>
        </styles.AccountDataWrapper>
        <ButtonIcon control={{ icon: <Close />, handler: closeModal }} />
      </styles.AccountModalHeader>
      <styles.PlanData>
        <styles.PlanName>{planName}</styles.PlanName>
        <styles.PlanDescription>{t("modals.account.planDescription", { date: "16 Nov" })}</styles.PlanDescription>
      </styles.PlanData>
      <ButtonPrimary
        name={t("actions.logOut")}
        iconLeft={<Logout />}
        color={EButtonColors.RED}
        handler={logoutHandler}
        isEmpty
      />
    </ModalWrapper>
  );
};
