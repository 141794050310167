import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { t } from "i18next";
import { Dispatch, SetStateAction } from "react";

import { AuthService } from "services/api/auth";
import ToastService from "shared/components/Toast";
import { EActionTypes, serverErrorHandler } from "shared/helpers/serverErrorHandler";
import { RootState } from "store";

export const applyResetPassword = createAsyncThunk<
  void,
  {
    password: string;
    code: string | undefined;
    setLoading: Dispatch<SetStateAction<boolean>>;
    closeHandler: () => void;
    reset: () => void;
  },
  { state: RootState }
>("account/applyResetPassword", async ({ password, code, setLoading, closeHandler, reset }) => {
  try {
    if (!code) return;

    setLoading(true);
    const { data } = await AuthService.applyPasswordReset(password, code);
    if (!data) return;

    closeHandler();
    ToastService.success({ text: t("modals.resetPassword.success") });

    reset();
  } catch (error) {
    if (error instanceof AxiosError) {
      ToastService.error({ text: serverErrorHandler(EActionTypes.PASSWORD_RESET, error?.response?.data.code) });
    }
    console.error("Error during password reset: ", error);
  } finally {
    setLoading(false);
  }
});
