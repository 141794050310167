export enum EApiRoutes {
  ACCOUNT = "account",
  SIGN_IN = "account/signin",
  SIGN_OUT = "account/signout",
  CONFIRM = "account/confirm",
  PASSWORD_RESET_REQUEST = "account/password_reset/request",
  PASSWORD_RESET_APPLY = "account/password_reset/apply",
  PLAN = "plan",
  TOKEN = "token",
  TOKEN_REVOKE = "token/revoke",
}
