import { createGlobalStyle } from "styled-components";

import { Theme } from "shared/theme/index";
import { hoverTransition } from "shared/theme/styles";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: ${({ theme }: { theme: Theme }) => theme.fonts.interSerif};
  }

  body, #root {
    background-color: ${({ theme }) => theme.colors.yellow};
    margin: 0;
    padding: 0;
    min-height: 100vh;
    position: relative;
  }

  #root {
    color: ${({ theme }) => theme.colors.black};
  }
  
  p {
    margin: 0;
  }
  
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.blue};
    transition: ${hoverTransition};

    &:hover {
      opacity: 0.9;
    }
  }

  .Toastify__toast-container {
    max-width: 27.625rem;
    width: fit-content;
    min-width: 20rem;
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export default GlobalStyle;
