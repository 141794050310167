import { useEffect } from "react";

import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { EModals, showModal } from "store/slices/modals";

import { EAuthModals } from "./enum";

export const AuthModalRouteWrapper: React.FC<{ modal: EAuthModals }> = ({ modal }: { modal: EAuthModals }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(showModal({ modal: EModals.AUTH_MODAL, props: { modal: modal } }));
  }, [modal, dispatch]);

  return <></>;
};
