import styled from "styled-components";

const AlertMessageBlockWrapper = styled.div`
  padding: 0.5rem;
  font-weight: 400;
  line-height: 140%;
  font-size: 0.875rem;
  border-radius: 0.5rem;
  margin: 2rem 0 1.5rem 0;
  color: ${({ theme }) => theme.colors.greyDark};
  background-color: ${({ theme }) => theme.colors.greyUltraLight};
`;

export default {
  AlertMessageBlockWrapper,
};
