import styled, { keyframes } from "styled-components";

const LoaderWrapper = styled.div`
  margin: 0.5rem 0;
  text-align: center;
`;

const animation = keyframes`
  0% { stroke-dashoffset: 0;}
  100% { stroke-dashoffset: -403px;}
`;

const SVG = styled.svg`
  filter: url(#gegga);
`;

const FirstStop = styled.stop`
  stop-color: ${({ theme }) => theme.colors.black};
`;

const SecondStop = styled.stop`
  stop-color: ${({ theme }) => theme.colors.black};
`;

const Half = styled.path`
  fill: none;
  stroke-width: 15;
  stroke-linecap: round;
  stroke: url(#gradient);
  stroke-dasharray: 180 800;
  animation: ${animation} 7s infinite linear;
`;

const Stretch = styled.circle`
  fill: none;
  stroke-width: 15;
  stroke-linecap: round;
  stroke: url(#gradient);
  stroke-dasharray: 26 54;
  animation: ${animation} 4s infinite linear;
`;

export default {
  LoaderWrapper,
  SVG,
  FirstStop,
  SecondStop,
  Half,
  Stretch,
};
