import i18n from "services/translation";

export enum EActionTypes {
  SIGN_IN = "SIGN_IN",
  SIGN_OUT = "SIGN_OUT",
  PASSWORD_RESET = "PASSWORD_RESET",
  TOKEN_CREATE = "TOKEN_CREATE",
  TOKEN_REVOKE = "TOKEN_REVOKE",
}

enum EErrorCodes {
  ERROR_400 = 400,
  ERROR_401 = 401,
  ERROR_404 = 404,
  ERROR_409 = 409,
}

export const serverErrorHandler = (actionType: EActionTypes, errorCode: EErrorCodes) => {
  if (actionType === EActionTypes.SIGN_IN) {
    switch (errorCode) {
      case EErrorCodes.ERROR_401:
        return i18n.t("toast.errors.incorrectSignInData");
      default:
        return i18n.t("toast.errors.somethingWentWrong");
    }
  }

  if (actionType === EActionTypes.SIGN_OUT) {
    switch (errorCode) {
      case EErrorCodes.ERROR_400:
      case EErrorCodes.ERROR_401:
        return i18n.t("toast.errors.sessionExpired");
      default:
        return i18n.t("toast.errors.somethingWentWrong");
    }
  }

  if (actionType === EActionTypes.PASSWORD_RESET) {
    switch (errorCode) {
      case EErrorCodes.ERROR_404:
        return i18n.t("toast.errors.invalidAccount");
      default:
        return i18n.t("toast.errors.somethingWentWrong");
    }
  }

  if (actionType === EActionTypes.TOKEN_CREATE) {
    switch (errorCode) {
      case EErrorCodes.ERROR_409:
        return i18n.t("toast.errors.tokenExist");
      default:
        return i18n.t("toast.errors.somethingWentWrong");
    }
  }

  if (actionType === EActionTypes.TOKEN_REVOKE) {
    switch (errorCode) {
      case EErrorCodes.ERROR_401:
        return i18n.t("toast.errors.sessionExpired");
      case EErrorCodes.ERROR_404:
        return i18n.t("toast.errors.tokenNotExist");
      default:
        return i18n.t("toast.errors.somethingWentWrong");
    }
  }

  return i18n.t("toast.errors.somethingWentWrong");
};
