import styled from "styled-components";

const AccountModalHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const AccountDataWrapper = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
`;

const Account = styled.div`
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  font-weight: 700;
  border-radius: 50%;
  font-size: 1.25rem;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.violet};
`;

const NameEmailWrapper = styled.div`
  gap: 0.25rem;
  display: flex;
  flex-direction: column;
`;

const Name = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.1875rem;
`;

const Email = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: ${({ theme }) => theme.colors.greyDark};
`;

const PlanData = styled.div`
  padding: 0.5rem;
  margin: 1.5rem 0;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.greyUltraLight};
`;

const PlanName = styled.div`
  font-size: 1rem;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 0.25rem;
`;

const PlanDescription = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: ${({ theme }) => theme.colors.greyDark};
`;

export default {
  AccountModalHeader,
  AccountDataWrapper,
  Account,
  NameEmailWrapper,
  Name,
  Email,
  PlanData,
  PlanName,
  PlanDescription,
};
