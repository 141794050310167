import { AxiosResponse } from "axios";

import api from "api";
import { EApiRoutes } from "shared/enum/api-routes";
import {
  IConfirmAccountResponse,
  IRequestPasswordApplyResponse,
  IRequestPasswordResetResponse,
  ISingInResponse,
  ISingOutResponse,
  ISingUpResponse,
} from "shared/interfaces";

export class AuthService {
  static async signUp(email: string, name: string, password: string): Promise<AxiosResponse<ISingUpResponse>> {
    return api.post<ISingUpResponse>(EApiRoutes.ACCOUNT, { email, name, password });
  }

  static async confirmAccount(code: string): Promise<AxiosResponse<IConfirmAccountResponse>> {
    return api.post<IConfirmAccountResponse>(EApiRoutes.CONFIRM, { security_code: code });
  }

  static async signIn(email: string, password: string): Promise<AxiosResponse<ISingInResponse>> {
    return api.post<ISingInResponse>(EApiRoutes.SIGN_IN, { email, password });
  }

  static async signOut(): Promise<AxiosResponse<ISingOutResponse>> {
    return api.post<ISingOutResponse>(EApiRoutes.SIGN_OUT, {});
  }

  static async requestPasswordReset(email: string): Promise<AxiosResponse<IRequestPasswordResetResponse>> {
    return api.post<IRequestPasswordResetResponse>(EApiRoutes.PASSWORD_RESET_REQUEST, { email });
  }

  static async applyPasswordReset(
    password: string,
    code: string
  ): Promise<AxiosResponse<IRequestPasswordApplyResponse>> {
    return api.post<IRequestPasswordApplyResponse>(EApiRoutes.PASSWORD_RESET_APPLY, {
      new_password: password,
      security_code: code,
    });
  }
}
