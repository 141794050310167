import { createAsyncThunk } from "@reduxjs/toolkit";

import { AccountService } from "services/api/account";
import { RootState } from "store";
import { loadAccountTokenList } from "store/actions/loadAccountTokenList";
import { loadPlan } from "store/actions/loadPlan";
import { EAccountStatus, setAccountData, setAccountInitialData } from "store/slices/account";

export const loadAccount = createAsyncThunk<void, void, { state: RootState }>(
  "account/loadAccount",
  async (_, { dispatch }) => {
    try {
      const { data } = await AccountService.getAccount();
      const { id, name, email, plan_id, status } = data;

      dispatch(loadPlan({ planId: plan_id }));
      dispatch(loadAccountTokenList());
      dispatch(setAccountData({ accountId: id, name, email, planId: plan_id, status: status as EAccountStatus }));
    } catch (error) {
      dispatch(setAccountInitialData());
      console.error("Error while loading account data: ", error);
    }
  }
);
