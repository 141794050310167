import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { EMPTY_STRING } from "shared/constants";
import { RootState } from "store";

export enum EAccountStatus {
  ACTIVE = "Active",
  UNCONFIRMED = "Unconfirmed",
}

export interface IAccountState {
  accountId: string;
  email: string;
  name: string;
  planId: string;
  status: EAccountStatus;
}

const initialState: IAccountState = {
  accountId: EMPTY_STRING,
  email: EMPTY_STRING,
  name: EMPTY_STRING,
  planId: EMPTY_STRING,
  status: EAccountStatus.UNCONFIRMED,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setAccountData: (state, action: PayloadAction<IAccountState>) => action.payload,
    setAccountInitialData: () => initialState,
  },
});

// SELECTORS
export const selectAccountData = (state: RootState) => state.account;

// ACTIONS
export const { setAccountData, setAccountInitialData } = accountSlice.actions;

export const accountReducer = accountSlice.reducer;
