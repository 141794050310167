import axios from "axios";

import { serverURL } from "services/config";

const api = axios.create({
  baseURL: serverURL,
});

api.interceptors.request.use((config) => {
  config.url = `/api/${config.url}`;
  config.headers.Authorization = `Bearer ${localStorage.getItem("account")}`;
  return config;
});

export default api;
