import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import commonEn from "assets/locales/en/en.json";

const resources = {
  en: {
    translation: commonEn,
  },
};

type TranslationKeys = `${keyof typeof commonEn}`;

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  returnObjects: true,
  keySeparator: ".",
  resources,
  returnNull: false,
});

export type ITranslationKeys = TranslationKeys | `${TranslationKeys}.${string}`;
export default i18n;
