import { t } from "i18next";
import { useState } from "react";
import { FieldErrors } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Copy } from "assets/images/icons/copy.svg";
import { ReactComponent as PasswordHide } from "assets/images/icons/password-hide.svg";
import { ReactComponent as PasswordShow } from "assets/images/icons/password-show.svg";
import { APP_ROUTES } from "routes/appRoutes";
import { Input } from "shared/components/Form/components/Input";
import { DefaultValuesType } from "shared/components/modals/AuthModal/helpers";
import { EMPTY_STRING } from "shared/constants";
import useCopy from "shared/hooks/useCopyToClipboard";

export const InputName: React.FC<{ errors: FieldErrors<DefaultValuesType> }> = ({
  errors,
}: {
  errors: FieldErrors<DefaultValuesType>;
}) => {
  return (
    <Input
      id="name"
      label={t("modals.common.inputs.name.label")}
      placeholder={t("modals.common.inputs.name.placeholder")}
      error={"name" in errors && Boolean(errors.name?.message)}
      errorMessage={"name" in errors ? errors.name?.message : EMPTY_STRING}
    />
  );
};

export const InputEmail: React.FC<{ errors: FieldErrors<DefaultValuesType> }> = ({
  errors,
}: {
  errors: FieldErrors<DefaultValuesType>;
}) => {
  return (
    <Input
      id="email"
      type="email"
      label={t("modals.common.inputs.email.label")}
      placeholder={t("modals.common.inputs.email.placeholder")}
      error={"email" in errors && Boolean(errors.email?.message)}
      errorMessage={"email" in errors ? errors.email?.message : EMPTY_STRING}
    />
  );
};

export const InputPassword: React.FC<{ errors: FieldErrors<DefaultValuesType>; isForgotPassword?: boolean }> = ({
  errors,
  isForgotPassword = false,
}: {
  errors: FieldErrors<DefaultValuesType>;
  isForgotPassword?: boolean;
}) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <Input
      id="password"
      type={showPassword ? undefined : "password"}
      label={t("modals.common.inputs.password.label")}
      placeholder={t("modals.common.inputs.password.placeholder")}
      error={"password" in errors && Boolean(errors.password?.message)}
      errorMessage={"password" in errors ? errors.password?.message : EMPTY_STRING}
      additionalInputHandler={{
        icon: showPassword ? <PasswordShow /> : <PasswordHide />,
        handler: () => setShowPassword(!showPassword),
      }}
      hintHandler={
        isForgotPassword
          ? {
              text: t("actions.forgotPassword"),
              handler: () => navigate(APP_ROUTES.PASSWORD_RESET),
            }
          : undefined
      }
    />
  );
};

export const InputTokenName: React.FC<{
  errors: FieldErrors<DefaultValuesType>;
  disabled?: boolean;
  value?: string;
}> = ({
  errors,
  disabled = false,
  value = EMPTY_STRING,
}: {
  errors: FieldErrors<DefaultValuesType>;
  disabled?: boolean;
  value?: string;
}) => {
  return (
    <Input
      id="name"
      value={value}
      disabled={disabled}
      label={t("modals.createToken.inputs.tokenName.label")}
      placeholder={t("modals.createToken.inputs.tokenName.placeholder")}
      error={"name" in errors && Boolean(errors.name?.message)}
      errorMessage={"name" in errors ? errors.name?.message : EMPTY_STRING}
    />
  );
};

export const InputApiToken: React.FC<{ apiToken: string }> = ({ apiToken }: { apiToken: string }) => {
  const { copyAction } = useCopy(apiToken);

  return (
    <Input
      id="api-token"
      label={t("modals.createToken.inputs.apiToken.label")}
      additionalInputHandler={{
        icon: <Copy />,
        handler: copyAction,
      }}
      disabled
      value={apiToken}
    />
  );
};
