import styled from "styled-components";

const AdminWrapper = styled.div`
  padding: 1.5rem 1rem 0 1rem;
`;

const TokensDataWrapper = styled.div`
  width: 50.5rem;
  max-width: 100%;
  overflow: hidden;
  border-radius: 1.5rem;
  margin: 0 auto 10.8125rem auto;
  background-color: ${({ theme }) => theme.colors.black};
`;

const TokensDataHeader = styled.div`
  padding: 1.5rem;
`;

const TokensDataHeaderTitle = styled.div`
  font-weight: 500;
  line-height: 1rem;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
  color: ${({ theme }) => theme.colors.greyMedium};
`;

const TokensDataHeaderValue = styled.div`
  font-size: 1rem;
  font-weight: 700;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.white};
`;

const TokensList = styled.div`
  padding: 1.5rem;
  border-radius: 1.5rem 1.5rem 0 0;
  background-color: ${({ theme }) => theme.colors.yellowLight};
`;

const TokenItem = styled.div`
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyMedium};

  button {
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
`;

const TokenName = styled.div`
  font-size: 1rem;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 0.5rem;
`;

const TokenUsageDate = styled.div`
  font-weight: 500;
  line-height: 1rem;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.greyDark};
`;

const TokenItemData = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TokenUnits = styled.div`
  text-align: right;
`;

const TokenUnitsTitle = styled.div`
  font-weight: 500;
  line-height: 1rem;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.greyDark};
`;

const TokenUnitsValue = styled.div`
  font-size: 1rem;
  font-weight: 700;
  line-height: 120%;
`;

const NoTokens = styled.div`
  margin: 4.5rem 0;
  font-weight: 500;
  line-height: 1rem;
  font-size: 0.75rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.greyMedium};
`;

export default {
  AdminWrapper,
  TokensDataWrapper,
  TokensDataHeader,
  TokensDataHeaderTitle,
  TokensDataHeaderValue,
  TokensList,
  TokenItem,
  TokenName,
  TokenUsageDate,
  TokenItemData,
  TokenUnits,
  TokenUnitsTitle,
  TokenUnitsValue,
  NoTokens,
};
