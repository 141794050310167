import { ReactComponent as CrossArrows } from "assets/images/icons/cross-arrows.svg";
import { ReactComponent as Leaf } from "assets/images/icons/leaf.svg";
import { ReactComponent as Wind } from "assets/images/icons/wind.svg";

export const infoBlocks = [
  {
    icon: <Leaf />,
    description: "pages.home.infoBlock.descriptions.easyAccess",
  },
  {
    icon: <CrossArrows />,
    description: "pages.home.infoBlock.descriptions.aggregatedData",
  },
  {
    icon: <Wind />,
    description: "pages.home.infoBlock.descriptions.performance",
  },
];
