import styled from "styled-components";

import { hoverTransition } from "shared/theme/styles";

const InputWrapper = styled.input<{ error?: boolean; additionalInputHandler?: boolean }>`
  width: 100%;
  outline: none;
  font-size: 1rem;
  height: 2.75rem;
  font-weight: 600;
  background: none;
  line-height: 120%;
  font-style: normal;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.red : theme.colors.greyMedium)};
  padding: ${({ additionalInputHandler }) => (additionalInputHandler ? "0.5rem 2.5rem 0.5rem 0.5rem" : "0.5rem")};

  &:focus,
  &:active {
    border: 1px solid ${({ theme, error }) => (error ? theme.colors.red : theme.colors.black)};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.greyLight};
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const AdditionalInputHandlerContainer = styled.div`
  position: relative;

  span {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
  }
`;

const HintHandler = styled.div`
  display: flex;
  margin-top: 0.5rem;
  justify-content: flex-end;
`;

const Handler = styled.div`
  cursor: pointer;
  font-weight: 700;
  text-align: right;
  line-height: 140%;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.greyMedium};
  transition: ${hoverTransition};

  &:hover {
    opacity: 0.9;
  }
`;

export default {
  InputWrapper,
  AdditionalInputHandlerContainer,
  HintHandler,
  Handler,
};
