import { Variants } from "framer-motion";
import { css } from "styled-components";

export const defaultVariant: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.3 } },
};

export const getFadeIn = (isInView: boolean) => css`
  opacity: ${isInView ? 1 : 0};
  transition: all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s;
`;
