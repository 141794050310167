export const APP_ROUTES = {
  HOME: "/",
  SIGN_IN: "/signin",
  SIGN_UP: "/signup",
  CONFIRM: "/confirm/:code",
  PASSWORD_RESET: "/password_reset",
  PASSWORD_RESET_APPLY: "/password_reset_apply/:code",
  ADMIN: "/admin",
  DEFAULT: "*",
};
