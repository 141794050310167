import { t } from "i18next";
import { FieldErrors } from "react-hook-form";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import { APP_ROUTES } from "routes/appRoutes";
import Checkbox from "shared/components/Form/components/Checkbox";
import { InputEmail, InputName, InputPassword } from "shared/components/modals/AuthModal/components/inputs";
import { DefaultValuesType } from "shared/components/modals/AuthModal/helpers";
import modalStyles from "shared/components/modals/styles";

export const SignUp: React.FC<{ errors: FieldErrors<DefaultValuesType> }> = ({
  errors,
}: {
  errors: FieldErrors<DefaultValuesType>;
}) => {
  return (
    <>
      <InputName errors={errors} />
      <InputEmail errors={errors} />
      <InputPassword errors={errors} />
      <Checkbox
        id="agreement"
        label={
          <Trans i18nKey="modals.signUp.termsAndConditions">
            {/* TODO: add link */}I agree with the <Link to="/">terms and conditions</Link>
          </Trans>
        }
        centered
      />
      <modalStyles.QuestionTextAndLink centered>
        {t("modals.signUp.haveAccount")}
        <Link to={APP_ROUTES.SIGN_IN}>{t("actions.signIn")}</Link>
      </modalStyles.QuestionTextAndLink>
    </>
  );
};
