import copyToClipboard from "copy-to-clipboard";
import { t } from "i18next";
import { useState, useRef, useEffect, useCallback } from "react";

import ToastService from "shared/components/Toast";

export default function useCopy(value: string) {
  const copyableString = useRef(value);

  const [copied, setCopied] = useState(false);

  const copyAction = useCallback(() => {
    const copiedString = copyToClipboard(copyableString.current);
    setCopied(copiedString);
    ToastService.success({ text: t("toast.copyToClipboard") });
  }, [copyableString]);

  useEffect(() => {
    copyableString.current = value;
  }, [value]);

  return { copied, copyAction, setCopied };
}
