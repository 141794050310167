import { t } from "i18next";
import { Dispatch, SetStateAction } from "react";
import { FieldErrors } from "react-hook-form";

import { ForgotPassword } from "shared/components/modals/AuthModal/components/authVariant/ForgotPassword";
import { ResetPassword } from "shared/components/modals/AuthModal/components/authVariant/ResetPassword";
import { SignIn } from "shared/components/modals/AuthModal/components/authVariant/SignIn";
import { SignUp } from "shared/components/modals/AuthModal/components/authVariant/SignUp";
import {
  createTokenDefaultValues,
  forgotPasswordDefaultValues,
  resetPasswordDefaultValues,
  signInDefaultValues,
  signUpDefaultValues,
} from "shared/components/modals/AuthModal/defaultValues";
import { EAuthModals } from "shared/components/modals/AuthModal/enum";
import {
  ICreateTokenFields,
  IForgotPasswordFields,
  IResetPasswordFields,
  ISignInFields,
  ISignUpFields,
} from "shared/components/modals/AuthModal/interface";
import {
  createTokenValidationSchema,
  forgotPasswordValidationSchema,
  resetPasswordValidationSchema,
  signInValidationSchema,
  signUpValidationSchema,
} from "shared/components/modals/AuthModal/schemas";
import { AppDispatch } from "store";
import { applyResetPassword } from "store/actions/applyResetPassword";
import { createToken } from "store/actions/createToken";
import { requestResetPassword } from "store/actions/requestResetPassword";
import { signIn } from "store/actions/signIn";
import { signUp } from "store/actions/signUp";

import { CreateToken } from "./components/authVariant/CreateToken";

type ValidationSchemaType =
  | typeof forgotPasswordValidationSchema
  | typeof resetPasswordValidationSchema
  | typeof signInValidationSchema
  | typeof signUpValidationSchema
  | typeof createTokenValidationSchema;

export type DefaultValuesType =
  | IForgotPasswordFields
  | IResetPasswordFields
  | ISignInFields
  | ISignUpFields
  | ICreateTokenFields;

export const getSchemaAndDefaultValues = (
  modal: EAuthModals,
  apiToken?: string
): {
  validationSchema: ValidationSchemaType;
  defaultValues: DefaultValuesType;
  modalName: string;
  buttonName: string;
} => {
  switch (modal) {
    case EAuthModals.FORGOT_PASSWORD:
      return {
        validationSchema: forgotPasswordValidationSchema,
        defaultValues: forgotPasswordDefaultValues,
        modalName: t("modals.forgotPassword.title"),
        buttonName: t("actions.requestResetLink"),
      };
    case EAuthModals.RESET_PASSWORD:
      return {
        validationSchema: resetPasswordValidationSchema,
        defaultValues: resetPasswordDefaultValues,
        modalName: t("modals.resetPassword.title"),
        buttonName: t("actions.resetPassword"),
      };
    case EAuthModals.SIGN_IN:
      return {
        validationSchema: signInValidationSchema,
        defaultValues: signInDefaultValues,
        modalName: t("modals.signIn.title"),
        buttonName: t("actions.signIn"),
      };
    case EAuthModals.SIGN_UP:
      return {
        validationSchema: signUpValidationSchema,
        defaultValues: signUpDefaultValues,
        modalName: t("modals.signUp.title"),
        buttonName: t("actions.signUp"),
      };
    case EAuthModals.CREATE_TOKEN:
      return {
        validationSchema: createTokenValidationSchema,
        defaultValues: createTokenDefaultValues,
        modalName: t("modals.createToken.title"),
        buttonName: apiToken ? t("actions.savedApiToken") : t("actions.generate"),
      };
    default:
      return {
        validationSchema: signUpValidationSchema,
        defaultValues: signUpDefaultValues,
        modalName: t("modals.signUp.title"),
        buttonName: t("actions.signUp"),
      };
  }
};

export const callButtonHandler = (
  modal: EAuthModals,
  data: DefaultValuesType,
  setLoading: Dispatch<SetStateAction<boolean>>,
  closeHandler: () => void,
  reset: () => void,
  dispatch: AppDispatch,
  code?: string,
  setApiToken?: Dispatch<SetStateAction<string>>,
  apiToken?: string
) => {
  if (typeof data === "object" && data !== null) {
    switch (modal) {
      case EAuthModals.SIGN_UP:
        if ("name" in data && "email" in data && "password" in data && "agreement" in data) {
          const { name, email, password } = data;
          dispatch(signUp({ email, name, password, setLoading, closeHandler, reset }));
        }
        break;
      case EAuthModals.SIGN_IN:
        if ("email" in data && "password" in data) {
          const { email, password } = data;
          dispatch(signIn({ email, password, setLoading, closeHandler, reset }));
        }
        break;
      case EAuthModals.FORGOT_PASSWORD:
        if ("email" in data) {
          const { email } = data;
          dispatch(requestResetPassword({ email, setLoading, closeHandler, reset }));
        }
        break;
      case EAuthModals.RESET_PASSWORD:
        if ("password" in data) {
          const { password } = data;
          dispatch(applyResetPassword({ password, code, setLoading, closeHandler, reset }));
        }
        break;
      case EAuthModals.CREATE_TOKEN:
        if ("name" in data && setApiToken) {
          const { name } = data;
          apiToken ? closeHandler() : dispatch(createToken({ name, setLoading, setApiToken }));
        }
        break;
      default:
        if ("name" in data && "email" in data && "password" in data && "agreement" in data) {
          const { name, email, password } = data;
          dispatch(signUp({ email, name, password, setLoading, closeHandler, reset }));
        }
        break;
    }
  }
};

export const getAuthVariant = (modal: EAuthModals, errors: FieldErrors<DefaultValuesType>, apiToken: string) => {
  switch (modal) {
    case EAuthModals.FORGOT_PASSWORD:
      return <ForgotPassword errors={errors} />;
    case EAuthModals.RESET_PASSWORD:
      return <ResetPassword errors={errors} />;
    case EAuthModals.SIGN_IN:
      return <SignIn errors={errors} />;
    case EAuthModals.SIGN_UP:
      return <SignUp errors={errors} />;
    case EAuthModals.CREATE_TOKEN:
      return <CreateToken errors={errors} apiToken={apiToken} />;
    default:
      <></>;
  }
};
