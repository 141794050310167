import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store";

export interface IToken {
  accountId: string;
  computeUnits: number;
  isActive: boolean;
  lastUsed: string;
  name: string;
  requests: number;
}

export interface ITokenListState {
  list: IToken[];
  totalComputedUnits: number;
}

const initialState: ITokenListState = {
  list: [],
  totalComputedUnits: 0,
};

const tokenListSlice = createSlice({
  name: "tokenList",
  initialState,
  reducers: {
    setTokenListData: (state, action: PayloadAction<IToken[]>) => {
      state.list = action.payload;
    },
    setTotalComputedUnits: (state, action: PayloadAction<number>) => {
      state.totalComputedUnits = action.payload;
    },
    setTokenListInitialData: () => initialState,
  },
});

// SELECTORS
export const selectTokenListData = (state: RootState) => state.tokenList;

// ACTIONS
export const { setTokenListData, setTokenListInitialData, setTotalComputedUnits } = tokenListSlice.actions;

export const tokenListReducer = tokenListSlice.reducer;
