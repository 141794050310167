import { motion } from "framer-motion";
import styled from "styled-components";

const InfoBlockWrapper = styled(motion.div)`
  width: 51rem;
  max-width: 100%;
  margin: 0 auto 8.5625rem auto;
  ${({ theme }) => theme.mediaWidth("upToMedium", `margin-bottom: 4.6875rem;`)};
`;

const BlocksWrapper = styled.div`
  gap: 1.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  ${({ theme }) => theme.mediaWidth("upToSmall", `grid-template-columns: repeat(1, 1fr);`)};
`;

const BlockItem = styled.div`
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      `
        gap: 1.125rem;
        display: flex;
        align-items: center;
      `
    )};
`;

const Icon = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  ${({ theme }) => theme.mediaWidth("upToSmall", `margin-bottom: 0;`)};

  svg {
    height: auto;
    width: 2.25rem;
    ${({ theme }) => theme.mediaWidth("upToSmall", `width: 1.5rem;`)};
  }
`;

const Description = styled.div`
  font-weight: 600;
  line-height: 120%;
  font-size: 1.5rem;
  text-align: center;
  ${({ theme }) => theme.mediaWidth("upToMedium", `font-size: 1rem;`)};
  ${({ theme }) => theme.mediaWidth("upToSmall", `text-align: left;`)};
`;

export default {
  InfoBlockWrapper,
  BlocksWrapper,
  BlockItem,
  Icon,
  Description,
};
