import { FieldErrors } from "react-hook-form";

import { InputPassword } from "shared/components/modals/AuthModal/components/inputs";
import { DefaultValuesType } from "shared/components/modals/AuthModal/helpers";

export const ResetPassword: React.FC<{ errors: FieldErrors<DefaultValuesType> }> = ({
  errors,
}: {
  errors: FieldErrors<DefaultValuesType>;
}) => {
  return <InputPassword errors={errors} />;
};
