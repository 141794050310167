import { Variants } from "framer-motion";

export const backgroundLayoutVariants: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const modalVariants: Variants = {
  initial: {
    y: "-20px",
    opacity: 0,
  },
  animate: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.3,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "20px",
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
};
