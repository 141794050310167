import { EButtonColors, IButtonProps } from "./interfaces";
import styles from "./styles";

export function ButtonPrimary({
  name,
  handler,
  iconLeft,
  iconRight,
  isEmpty = false,
  fullWidth = false,
  isCentered = false,
  color = EButtonColors.BLACK,
  disabled,
}: IButtonProps) {
  return (
    <styles.ButtonPrimaryWrapper
      type="button"
      onClick={handler}
      color={color}
      isEmpty={isEmpty}
      fullWidth={fullWidth}
      isCentered={isCentered}
      disabled={disabled}
    >
      {iconLeft || null}
      {name}
      {iconRight || null}
    </styles.ButtonPrimaryWrapper>
  );
}
