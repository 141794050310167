/* eslint-disable max-len */
import styles from "./styles";

const Loader: React.FC = () => {
  return (
    <styles.LoaderWrapper>
      <styles.SVG width="60px" height="60px" viewBox="0 0 200 200">
        <defs>
          <linearGradient id="linearGradient">
            <styles.FirstStop offset="0" />
            <styles.SecondStop offset="1" />
          </linearGradient>
          <linearGradient
            y2="160"
            x2="160"
            y1="40"
            x1="40"
            gradientUnits="userSpaceOnUse"
            id="gradient"
            xlinkHref="#linearGradient"
          />
        </defs>
        <styles.Half d="m 164,100 c 0,-35.346224 -28.65378,-64 -64,-64 -35.346224,0 -64,28.653776 -64,64 0,35.34622 28.653776,64 64,64 35.34622,0 64,-26.21502 64,-64 0,-37.784981 -26.92058,-64 -64,-64 -37.079421,0 -65.267479,26.922736 -64,64 1.267479,37.07726 26.703171,65.05317 64,64 37.29683,-1.05317 64,-64 64,-64" />
        <styles.Stretch cx="100" cy="100" r="64" />
      </styles.SVG>
    </styles.LoaderWrapper>
  );
};

export default Loader;
