import { t } from "i18next";
import React from "react";

import styles from "shared/components/Footer/styles";
import { Logo } from "shared/components/Logo";
import { Translate } from "shared/components/Translate";
import { footerLinks } from "shared/constants/links";

export const Footer: React.FC = () => {
  return (
    <styles.FooterWrapper>
      <styles.LogoCopyright>
        <Logo isAlt />
        <styles.Copyright>{t("footer.copyright", { year: new Date().getFullYear() })}</styles.Copyright>
      </styles.LogoCopyright>
      {/* TODO: add links */}
      <styles.TermsAndPrivacy>
        <a href="#">
          <Translate value="footer.terms" />
        </a>
        <a href="#">
          <Translate value="footer.privacy" />
        </a>
        <a href={footerLinks.apiDocs} target="_blank" rel="noreferrer">
          <Translate value="footer.hapiApiDocs" />
        </a>
      </styles.TermsAndPrivacy>
    </styles.FooterWrapper>
  );
};
