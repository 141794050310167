import styled from "styled-components";

const ModalDescriptionWrapper = styled.div`
  font-weight: 400;
  line-height: 140%;
  font-size: 0.75rem;
  text-align: center;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors.grey};
`;

export default {
  ModalDescriptionWrapper,
};
