import { useFormContext } from "react-hook-form";

import { ICheckboxProps } from "shared/components/Form/components/Checkbox/interface";
import styles from "shared/components/Form/components/Checkbox/styles";

const Checkbox: React.FC<ICheckboxProps> = ({
  id,
  label,
  onChange,
  centered = false,
  defaultChecked = false,
  ...rest
}: ICheckboxProps) => {
  const { register } = useFormContext();

  return (
    <styles.InputWrapper centered={centered}>
      <styles.Box>
        <input
          {...rest}
          type="checkbox"
          {...register(id, {
            onChange,
          })}
          defaultChecked={defaultChecked}
        />
        <svg viewBox="0 0 21 21">
          <polyline points="5 10.75 8.5 14.25 16 6" />
        </svg>
      </styles.Box>
      <styles.Label>{label}</styles.Label>
    </styles.InputWrapper>
  );
};

export default Checkbox;
