import { motion } from "framer-motion";
import styled from "styled-components";

const BackgroundLayout = styled(motion.div)`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 199;
  height: 100%;
  display: flex;
  overflow: auto;
  position: fixed;
  justify-content: center;
  backdrop-filter: blur(8px);
  background: ${({ theme }) => theme.colors.modalBG};
`;

const Modal = styled(motion.div)<{ $widthInRem: string; $clearDefaultStyles?: boolean }>`
  z-index: 200;
  max-width: 90%;
  overflow: auto;
  max-height: 90vh;
  position: relative;
  height: fit-content;
  align-self: center;
  width: ${({ $widthInRem }) => `${$widthInRem}rem`};
  padding: ${({ $clearDefaultStyles }) => ($clearDefaultStyles ? "0" : "1rem")};
  border-radius: ${({ $clearDefaultStyles }) => ($clearDefaultStyles ? "0" : "1.5rem")};
  background-color: ${({ theme, $clearDefaultStyles }) =>
    $clearDefaultStyles ? "transparent" : theme.colors.yellowLight};
`;

export default {
  BackgroundLayout,
  Modal,
};
