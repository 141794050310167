import { IConfig } from "services/config/interface";

import development from "./environments/development";
import production from "./environments/production";

enum EEnvironment {
  DEVELOPMENT = "development",
  PRODUCTION = "production",
}

const environments: { [key in EEnvironment]: IConfig } = {
  [EEnvironment.DEVELOPMENT]: development,
  [EEnvironment.PRODUCTION]: production,
};

const currentEnvironment: EEnvironment = (process.env.REACT_APP_ENVIRONMENT as EEnvironment) || EEnvironment.PRODUCTION;

export const { serverURL }: IConfig = {
  ...environments[currentEnvironment],
};
