export const EMPTY_STRING = "";
export const ZERO_STRING = "0";

export const accountLocalStorage = "account";

export const MEDIA_WIDTHS = {
  upToSmall: 600,
  upToMedium: 900,
  upToLarge: 1280,
};

export enum EDimensions {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  EXTRA_LARGE = "EXTRA_LARGE",
  UNKNOWN = "UNKNOWN",
}

export const DEFAULT_MODAL_WIDTH_IN_REM = "20.5";
export const WIDE_MODAL_WIDTH_IN_REM = "30.5";
