import { motion } from "framer-motion";
import styled from "styled-components";

import typography from "shared/components/Typography/styles";

const TariffsBlockWrapper = styled(motion.div)`
  padding: 3rem 1rem 2.75rem 1rem;
  border-radius: 1.5rem 1.5rem 0 0;
  background-color: ${({ theme }) => theme.colors.black};
`;

const H3 = styled(typography.H3)`
  width: 18.5rem;
  max-width: 100%;
  text-align: center;
  margin: 0 auto 3rem auto;
  color: ${({ theme }) => theme.colors.yellowLightAlt};
`;

export default {
  TariffsBlockWrapper,
  H3,
};
