import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Dispatch, SetStateAction } from "react";

import { TokenService } from "services/api/token";
import ToastService from "shared/components/Toast";
import { EActionTypes, serverErrorHandler } from "shared/helpers/serverErrorHandler";
import { RootState } from "store";
import { loadAccountTokenList } from "store/actions/loadAccountTokenList";

export const revokeToken = createAsyncThunk<
  void,
  {
    name: string;
    setLoading: Dispatch<SetStateAction<boolean>>;
    closeHandler: () => void;
  },
  { state: RootState }
>("token/revokeToken", async ({ name, setLoading, closeHandler }, { getState, dispatch }) => {
  try {
    console.warn("Revoke in thunk");
    setLoading(true);
    const state = getState();
    const accountId = state.account.accountId;
    console.warn({ accountId });
    console.warn({ name });
    if (!accountId || !name) return;

    const { data } = await TokenService.revokeToken(name, accountId);
    console.warn({ data });
    if (!data) return;

    closeHandler();
    dispatch(loadAccountTokenList());
  } catch (error) {
    if (error instanceof AxiosError) {
      ToastService.error({ text: serverErrorHandler(EActionTypes.TOKEN_REVOKE, error?.response?.data.code) });
    }
    console.error("Error during token revoke: ", error);
  } finally {
    setLoading(false);
  }
});
