import { motion } from "framer-motion";
import styled from "styled-components";

const MainBlockWrapper = styled(motion.div)`
  gap: 4.5rem;
  width: 64rem;
  display: flex;
  max-width: 100%;
  align-items: center;
  margin: 3.6875rem auto 9.5625rem auto;
  ${({ theme }) => theme.mediaWidth("upToMedium", `gap: 2rem; margin-bottom: 4rem;`)};
  ${({ theme }) => theme.mediaWidth("upToSmall", `gap: 1rem; flex-wrap: wrap; margin-bottom: 4rem;`)};
`;

const TitleDescription = styled.div`
  width: 51.25%;
  max-width: 100%;
  ${({ theme }) => theme.mediaWidth("upToSmall", `order: 2; width: 100%;`)};
`;

const Description = styled.div`
  font-weight: 400;
  line-height: 140%;
  font-size: 1.3125rem;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors.grey};
  ${({ theme }) => theme.mediaWidth("upToMedium", `font-size: 1rem;`)};
  ${({ theme }) => theme.mediaWidth("upToSmall", `font-size: 0.875rem;`)};
`;

const MainBlockImageWrapper = styled.div`
  width: 48.75%;
  max-width: 100%;
  ${({ theme }) => theme.mediaWidth("upToSmall", `order: 1; width: 100%;`)};

  svg {
    width: 100%;
    height: auto;
  }
`;

export default {
  MainBlockWrapper,
  TitleDescription,
  Description,
  MainBlockImageWrapper,
};
