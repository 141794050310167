import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { EMPTY_STRING } from "shared/constants";
import { RootState } from "store";

export enum EPlanNames {
  FREE = "Free",
  NONE = "None",
}

export interface IPlanState {
  computeUnitsPerMinute: number;
  id: string;
  isActive: boolean;
  monthlyComputeUnits: number;
  name: EPlanNames;
}

const initialState: IPlanState = {
  computeUnitsPerMinute: 0,
  id: EMPTY_STRING,
  isActive: false,
  monthlyComputeUnits: 0,
  name: EPlanNames.NONE,
};

const planSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    setPlanData: (state, action: PayloadAction<IPlanState>) => action.payload,
    setPlanInitialData: () => initialState,
  },
});

// SELECTORS
export const selectPlanData = (state: RootState) => state.plan;

// ACTIONS
export const { setPlanData, setPlanInitialData } = planSlice.actions;

export const planReducer = planSlice.reducer;
