import styled from "styled-components";

import { hoverTransition } from "shared/theme/styles";

const HeaderWrapper = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5968rem 1.1075rem;
  justify-content: space-between;
`;

const AuthWrapper = styled.div`
  gap: 1.25rem;
  display: flex;
  align-items: center;

  a {
    font-weight: 700;
    line-height: 140%;
    font-size: 0.875rem;
    transition: ${hoverTransition};

    &:hover {
      opacity: 0.9;
    }
  }
`;

const AccountHandler = styled.div`
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  font-weight: 700;
  border-radius: 50%;
  font-size: 1.25rem;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.violet};
  transition: ${hoverTransition};

  &:hover {
    opacity: 0.9;
  }
`;

export default {
  HeaderWrapper,
  AuthWrapper,
  AccountHandler,
};
