import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import { APP_ROUTES } from "routes/appRoutes";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import Loader from "shared/components/Loader";
import { ModalControlsHeader } from "shared/components/modals/components/ModalControlsHeader";
import { ModalDescription } from "shared/components/modals/components/ModalDescription";
import ModalWrapper from "shared/components/modals/ModalWrapper";
import { IMessageModalProps } from "shared/interfaces";

export const MessageModal: React.FC<IMessageModalProps> = ({
  title,
  description,
  loading = false,
  closeModal,
}: IMessageModalProps) => {
  const navigate = useNavigate();

  const closeHandler = () => {
    navigate(APP_ROUTES.HOME);
    closeModal();
  };

  return (
    <ModalWrapper closeModal={closeHandler}>
      <ModalControlsHeader
        smallIndent={description || loading ? true : false}
        title={title}
        rightControl={{ icon: <CloseIcon />, handler: closeHandler }}
      />
      {description && <ModalDescription text={description} />}
      {loading && <Loader />}
      <ButtonPrimary name={t("actions.ok")} handler={closeHandler} disabled={loading} fullWidth />
    </ModalWrapper>
  );
};
