import { ButtonIcon } from "shared/components/Buttons/ButtonIcon";
import { IModalControlsHeaderProps } from "shared/components/modals/components/ModalControlsHeader/interface";
import styles from "shared/components/modals/components/ModalControlsHeader/styles";

export function ModalControlsHeader({
  title,
  smallIndent = false,
  leftControl,
  rightControl,
}: IModalControlsHeaderProps) {
  return (
    <styles.ModalControlsHeaderWrapper smallIndent={smallIndent}>
      <div>
        <ButtonIcon control={leftControl} />
      </div>
      <styles.Title>{title}</styles.Title>
      <div>
        <ButtonIcon control={rightControl} />
      </div>
    </styles.ModalControlsHeaderWrapper>
  );
}
