import styles from "pages/Home/components/TariffsBlock/styles";
import { defaultVariant } from "shared/components/Animations/landing";
import { Tariffs } from "shared/components/Tariffs";
import { Translate } from "shared/components/Translate";

export const TariffsBlock: React.FC = () => {
  return (
    <styles.TariffsBlockWrapper variants={defaultVariant} initial="initial" animate="animate">
      <styles.H3>
        <Translate value="pages.home.tariffBlock.title" />
      </styles.H3>
      <Tariffs />
    </styles.TariffsBlockWrapper>
  );
};
