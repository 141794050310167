import styles from "./styles";

export const successContent = (text: string, additionalText?: string | null) => (
  <styles.Container>
    <styles.SuccessIcon />
    <styles.Description>
      <p>{text}</p>
      {additionalText && <p>{additionalText}</p>}
    </styles.Description>
  </styles.Container>
);

export const errorContent = (text: string) => (
  <styles.Container>
    <styles.ErrorIcon />
    <styles.Description $error>
      <p>{text}</p>
    </styles.Description>
  </styles.Container>
);
