import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import { AdminPage } from "pages/Admin";
import { HomePage } from "pages/Home";
import { APP_ROUTES } from "routes/appRoutes";
import { ProtectedRoute } from "routes/ProtectedRoute";
import styles from "routes/styles";
import { Footer } from "shared/components/Footer";
import { Header } from "shared/components/Header";
import { EAuthModals } from "shared/components/modals/AuthModal/enum";
import { AuthModalRouteWrapper } from "shared/components/modals/AuthModal/routeWrapper";
import { RegistrationConfirmationRouteWrapper } from "shared/components/modals/ConfirmModals/routeWrapper";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { loadAccount } from "store/actions/loadAccount";
import { closeModal, EModals, IModalsProps, MODALS, selectModalState } from "store/slices/modals";

export const AppRoutes: React.FC = () => {
  const modalState = useAppSelector(selectModalState);
  const dispatch = useAppDispatch();
  const Modal: IModalsProps[EModals] = MODALS[modalState.modal];

  useEffect(() => {
    dispatch(loadAccount());
  }, [dispatch]);

  return (
    <Router>
      <styles.Container>
        <Header />
        <AnimatePresence>
          {Modal && <Modal closeModal={() => dispatch(closeModal())} {...modalState.props} />}
        </AnimatePresence>
        <styles.Content>
          <Routes>
            <Route path={APP_ROUTES.HOME} element={<HomePage />}>
              <Route path={APP_ROUTES.SIGN_UP} element={<AuthModalRouteWrapper modal={EAuthModals.SIGN_UP} />} />
              <Route path={APP_ROUTES.SIGN_IN} element={<AuthModalRouteWrapper modal={EAuthModals.SIGN_IN} />} />
              <Route
                path={APP_ROUTES.PASSWORD_RESET}
                element={<AuthModalRouteWrapper modal={EAuthModals.FORGOT_PASSWORD} />}
              />
              <Route
                path={APP_ROUTES.PASSWORD_RESET_APPLY}
                element={<AuthModalRouteWrapper modal={EAuthModals.RESET_PASSWORD} />}
              />
              <Route path={APP_ROUTES.CONFIRM} element={<RegistrationConfirmationRouteWrapper />} />
            </Route>
            <Route
              path={APP_ROUTES.ADMIN}
              element={
                <ProtectedRoute>
                  <AdminPage />
                </ProtectedRoute>
              }
            />
            <Route path={APP_ROUTES.DEFAULT} element={<Navigate replace to={APP_ROUTES.ADMIN} />} />
          </Routes>
        </styles.Content>
        <Footer />
      </styles.Container>
    </Router>
  );
};
