export const colors = {
  violet: "#B486FF",
  blue: "#1C1C39",
  red: "#FF4E57",
  black: "#131313",
  white: "#FFFFFF",
  yellow: "#FDEE2D",
  grey: "#59596A",
  greyDark: "#494961",
  greyLight: "#B8B8BF",
  greyMedium: "#777788",
  greyUltraLight: "#F2F2CB",
  yellowLight: "#FFFFD2",
  yellowLightAlt: "#FFF9AA",
  modalBG: "rgba(0, 0, 0, 0.6)",
};
