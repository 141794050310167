import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import { APP_ROUTES } from "routes/appRoutes";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { tariffs } from "shared/components/Tariffs/data";
import styles from "shared/components/Tariffs/styles";
import { Translate } from "shared/components/Translate";

export const Tariffs: React.FC = () => {
  const navigate = useNavigate();

  console.log("process.env.REACT_APP_CONTACT_SALES", process.env.REACT_APP_CONTACT_SALES);

  return (
    <styles.TariffsWrapper>
      {tariffs.map((tariff) => (
        <styles.TariffBlock key={tariff.name}>
          <styles.TariffName>{t(tariff.name)}</styles.TariffName>
          <styles.TariffDescription>{t(tariff.description)}</styles.TariffDescription>
          <styles.TitleDescription>
            <styles.Title>
              <Translate value="tariffs.titles.computeResources" />
            </styles.Title>
            <styles.Description>{t(tariff.computeResources)}</styles.Description>
          </styles.TitleDescription>
          <styles.TitleDescription>
            <styles.Title>
              <Translate value="tariffs.titles.requestThroughput" />
            </styles.Title>
            <styles.Description>{t(tariff.requestThroughput)}</styles.Description>
          </styles.TitleDescription>
          <styles.TitleDescription>
            <styles.Title>
              <Translate value="tariffs.titles.price" />
            </styles.Title>
            <styles.Description>{t(tariff.price)}</styles.Description>
          </styles.TitleDescription>
          <styles.ButtonWrapper>
            <ButtonPrimary
              name={t("actions.signUp", { value: t(tariff.name) })}
              handler={() => navigate(APP_ROUTES.SIGN_UP)}
              fullWidth
            />
          </styles.ButtonWrapper>
        </styles.TariffBlock>
      ))}
      <styles.TariffBlock>
        <styles.TariffName>
          <Translate value="tariffs.enterprise.name" />
        </styles.TariffName>
        <styles.TitleEnterprise>
          <Translate value="tariffs.enterprise.title" />
        </styles.TitleEnterprise>
        <styles.DescriptionEnterprise>
          <Translate value="tariffs.enterprise.description" />
        </styles.DescriptionEnterprise>
        <styles.ButtonWrapper>
          <ButtonPrimary
            name={t("actions.contactSales")}
            handler={() => window.open(process.env.REACT_APP_CONTACT_SALES, "_blank")}
            fullWidth
          />
        </styles.ButtonWrapper>
      </styles.TariffBlock>
    </styles.TariffsWrapper>
  );
};
