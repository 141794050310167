import { t } from "i18next";
import { FieldErrors } from "react-hook-form";
import { Link } from "react-router-dom";

import { APP_ROUTES } from "routes/appRoutes";
import { InputEmail, InputPassword } from "shared/components/modals/AuthModal/components/inputs";
import { DefaultValuesType } from "shared/components/modals/AuthModal/helpers";
import modalStyles from "shared/components/modals/styles";

export const SignIn: React.FC<{ errors: FieldErrors<DefaultValuesType> }> = ({
  errors,
}: {
  errors: FieldErrors<DefaultValuesType>;
}) => {
  return (
    <>
      <InputEmail errors={errors} />
      <InputPassword errors={errors} isForgotPassword />
      <modalStyles.QuestionTextAndLink centered>
        {t("modals.signIn.noAccount")}
        <Link to={APP_ROUTES.SIGN_UP}>{t("actions.signUp")}</Link>
      </modalStyles.QuestionTextAndLink>
    </>
  );
};
