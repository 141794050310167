import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";

import { AppRoutes } from "routes";
import theme, { GlobalStyle } from "shared/theme";
import store from "store";

import "services/translation";

import reportWebVitals from "./reportWebVitals";

import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

function AppWrapper() {
  return (
    <div>
      <GlobalStyle />
      <AppRoutes />
      <ToastContainer />
    </div>
  );
}

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AppWrapper />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
