import { createAsyncThunk } from "@reduxjs/toolkit";

import { AccountService } from "services/api/account";
import { RootState } from "store";
import { EPlanNames, setPlanData, setPlanInitialData } from "store/slices/plan";

export const loadPlan = createAsyncThunk<
  void,
  {
    planId: string;
  },
  { state: RootState }
>("plan/loadPlan", async ({ planId }, { dispatch }) => {
  try {
    const { data } = await AccountService.getPlan(planId);
    const { compute_units_per_minute, id, is_active, monthly_compute_units, name } = data;

    dispatch(
      setPlanData({
        computeUnitsPerMinute: compute_units_per_minute,
        id,
        isActive: is_active,
        monthlyComputeUnits: monthly_compute_units,
        name: name as EPlanNames,
      })
    );
  } catch (error) {
    dispatch(setPlanInitialData());
    console.error("Error while loading plan data: ", error);
  }
});
