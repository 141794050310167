import { t } from "i18next";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { APP_ROUTES } from "routes/appRoutes";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import styles from "shared/components/Header/styles";
import { Logo } from "shared/components/Logo";
import { Translate } from "shared/components/Translate";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { selectAccountData } from "store/slices/account";
import { EModals, showModal } from "store/slices/modals";

export const Header: React.FC = () => {
  const navigate = useNavigate();
  const { accountId, name } = useAppSelector(selectAccountData);
  const dispatch = useAppDispatch();

  const showAccountModal = () => {
    dispatch(showModal({ modal: EModals.ACCOUNT_MODAL, props: {} }));
  };

  return (
    <styles.HeaderWrapper>
      <Logo />
      {!accountId ? (
        <styles.AuthWrapper>
          <Link to={APP_ROUTES.SIGN_IN}>
            <Translate value="actions.signIn" />
          </Link>
          <ButtonPrimary name={t("actions.signUp")} handler={() => navigate(APP_ROUTES.SIGN_UP)} />
        </styles.AuthWrapper>
      ) : (
        <styles.AccountHandler onClick={showAccountModal}>{name.substring(0, 1)}</styles.AccountHandler>
      )}
    </styles.HeaderWrapper>
  );
};
