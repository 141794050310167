import styled from "styled-components";

const QuestionTextAndLink = styled.div<{ centered?: boolean }>`
  margin: 1.5rem 0;
  font-weight: 400;
  line-height: 140%;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.blue};
  text-align: ${({ centered }) => (centered ? "center" : "left")};

  a {
    font-weight: 700;
    margin-left: 0.5rem;
  }
`;

export default { QuestionTextAndLink };
