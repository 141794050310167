import styled, { keyframes } from "styled-components";

import { flexRowNoWrap, hoverTransition } from "shared/theme/styles";

const InputWrapper = styled.div<{ centered?: boolean }>`
  ${flexRowNoWrap}
  align-items: start;
  position: relative;
  justify-content: ${({ centered }) => (centered ? "center" : "flex-start")};
`;

const bounce = keyframes`
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
`;

const Box = styled.label`
  position: relative;

  input,
  svg {
    width: 1rem;
    height: 1rem;
    display: block;
  }

  input {
    margin: 0;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    -moz-appearance: none;
    border-radius: 0.375rem;
    -webkit-appearance: none;
    background: ${({ theme }) => theme.colors.transparent};
    border: 1px solid ${({ theme }) => theme.colors.greyMedium};
    transition: ${hoverTransition};

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.black};
    }

    &:checked {
      border: 1px solid ${({ theme }) => theme.colors.black};
      box-shadow: inset 0 0 0 0.688rem ${({ theme }) => theme.colors.black};

      & + svg {
        animation: ${bounce} 0.4s linear forwards 0.2s;
      }
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  svg {
    top: 0;
    left: 0;
    fill: none;
    stroke-width: 2px;
    position: absolute;
    pointer-events: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    transform: scale(0) translateZ(0);
    stroke: ${({ theme }) => theme.colors.white};
  }
`;

const Label = styled.div`
  font-weight: 400;
  line-height: 120%;
  font-size: 0.875rem;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.blue};

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

export default {
  InputWrapper,
  Box,
  Label,
};
