import { t } from "i18next";
import { v4 as uuid } from "uuid";

import { ReactComponent as Plus } from "assets/images/icons/plus.svg";
import styles from "pages/Admin/styles";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { EButtonColors } from "shared/components/Buttons/interfaces";
import { EAuthModals } from "shared/components/modals/AuthModal/enum";
import { Translate } from "shared/components/Translate";
import typography from "shared/components/Typography/styles";
import { WIDE_MODAL_WIDTH_IN_REM } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { selectAccountData } from "store/slices/account";
import { EModals, showModal } from "store/slices/modals";
import { selectPlanData } from "store/slices/plan";
import { selectTokenListData } from "store/slices/tokenList";

export const AdminPage: React.FC = () => {
  const { name } = useAppSelector(selectAccountData);
  const { list, totalComputedUnits } = useAppSelector(selectTokenListData);
  const { monthlyComputeUnits } = useAppSelector(selectPlanData);
  const dispatch = useAppDispatch();

  const openCreateTokenModal = () => {
    dispatch(
      showModal({
        modal: EModals.AUTH_MODAL,
        props: { modal: EAuthModals.CREATE_TOKEN, widthInRem: WIDE_MODAL_WIDTH_IN_REM },
      })
    );
  };

  const openRevokeModal = (name: string) => {
    dispatch(
      showModal({
        modal: EModals.REVOKE_MODAL,
        props: { name },
      })
    );
  };

  return (
    <styles.AdminWrapper>
      <typography.H2 isCentered>{t("pages.admin.title", { name: name })}</typography.H2>
      <styles.TokensDataWrapper>
        <styles.TokensDataHeader>
          <styles.TokensDataHeaderTitle>
            <Translate value="pages.admin.totalComputeUnitsUsed.title" />
          </styles.TokensDataHeaderTitle>
          <styles.TokensDataHeaderValue>
            {t("pages.admin.totalComputeUnitsUsed.value", { used: totalComputedUnits, total: monthlyComputeUnits })}
          </styles.TokensDataHeaderValue>
        </styles.TokensDataHeader>
        <styles.TokensList>
          {list.length ? (
            list.map((listItem) => (
              <styles.TokenItem key={uuid()}>
                <styles.TokenItemData>
                  <div>
                    <styles.TokenName>{listItem.name}</styles.TokenName>
                    <styles.TokenUsageDate>
                      {listItem.lastUsed
                        ? t("pages.admin.lastTokenUsage", { date: listItem.lastUsed })
                        : t("pages.admin.tokenNotUsed")}
                    </styles.TokenUsageDate>
                  </div>
                  <styles.TokenUnits>
                    <styles.TokenUnitsTitle>
                      <Translate value="pages.admin.computeUnits" />
                    </styles.TokenUnitsTitle>
                    <styles.TokenUnitsValue>{listItem.computeUnits}</styles.TokenUnitsValue>
                  </styles.TokenUnits>
                </styles.TokenItemData>
                <ButtonPrimary
                  name={listItem.isActive ? t("actions.revoke") : t("pages.admin.revoked")}
                  handler={() => openRevokeModal(listItem.name)}
                  isEmpty
                  disabled={!listItem.isActive}
                  color={EButtonColors.RED}
                />
              </styles.TokenItem>
            ))
          ) : (
            <styles.NoTokens>
              <Translate value="pages.admin.noTokens" />
            </styles.NoTokens>
          )}
          <ButtonPrimary
            isCentered
            name={t("actions.createToken")}
            iconLeft={<Plus />}
            handler={openCreateTokenModal}
          />
        </styles.TokensList>
      </styles.TokensDataWrapper>
    </styles.AdminWrapper>
  );
};
