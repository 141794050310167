import styled from "styled-components";

const InputWithError = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.div`
  font-weight: 500;
  line-height: 120%;
  font-size: 0.75rem;
  padding-left: 0.5rem;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.greyDark};
`;

export default {
  InputWithError,
  Label,
};
