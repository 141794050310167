import { t } from "i18next";
import { v4 as uuid } from "uuid";

import { infoBlocks } from "pages/Home/components/InformationBlocks/data";
import styles from "pages/Home/components/InformationBlocks/styles";
import { defaultVariant } from "shared/components/Animations/landing";
import { Translate } from "shared/components/Translate";
import typography from "shared/components/Typography/styles";

export const InformationBlocks: React.FC = () => {
  return (
    <styles.InfoBlockWrapper variants={defaultVariant} initial="initial" animate="animate">
      <typography.H2 isCentered>
        <Translate value="pages.home.infoBlock.title" />
      </typography.H2>
      <styles.BlocksWrapper>
        {infoBlocks.map((block) => (
          <styles.BlockItem key={uuid()}>
            <styles.Icon>{block.icon}</styles.Icon>
            <styles.Description>{t(block.description)}</styles.Description>
          </styles.BlockItem>
        ))}
      </styles.BlocksWrapper>
    </styles.InfoBlockWrapper>
  );
};
