import theme from "shared/theme";

import { EButtonColors, IButtonStatusColors } from "./interfaces";

export function getButtonStatusColors(color: EButtonColors): IButtonStatusColors {
  switch (color) {
    case EButtonColors.BLACK:
      return {
        default: theme.colors.black,
        active: theme.colors.black,
        hover: theme.colors.black,
        text: theme.colors.yellow,
      };
    case EButtonColors.RED:
      return {
        default: theme.colors.red,
        active: theme.colors.red,
        hover: theme.colors.red,
        text: theme.colors.white,
      };
    case EButtonColors.TRANSPARENT:
      return {
        default: theme.colors.black,
        active: theme.colors.black,
        hover: theme.colors.black,
        text: theme.colors.black,
      };
    default:
      return {
        default: theme.colors.black,
        active: theme.colors.black,
        hover: theme.colors.black,
        text: theme.colors.yellow,
      };
  }
}
