import { Navigate, Outlet } from "react-router-dom";

import { InformationBlocks } from "pages/Home/components/InformationBlocks";
import { MainBlock } from "pages/Home/components/MainBlock";
import { TariffsBlock } from "pages/Home/components/TariffsBlock";
import styles from "pages/Home/styles";
import { APP_ROUTES } from "routes/appRoutes";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { selectAccountData } from "store/slices/account";

export const HomePage: React.FC = () => {
  const { accountId } = useAppSelector(selectAccountData);
  if (accountId) return <Navigate to={APP_ROUTES.ADMIN} replace />;

  return (
    <>
      <styles.MainAndInfoWrapper>
        <MainBlock />
        <InformationBlocks />
      </styles.MainAndInfoWrapper>
      <TariffsBlock />
      <Outlet />
    </>
  );
};
