import styled from "styled-components";

const InputErrorWrapper = styled.div`
  font-size: 0.75rem;
  margin-top: 0.5rem;
  padding-left: 0.5rem;
  color: ${({ theme }) => theme.colors.red};
`;

export default {
  InputErrorWrapper,
};
