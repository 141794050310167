import { t } from "i18next";
import { useState } from "react";

import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import { AlertMessageBlock } from "shared/components/AlertMessageBlock";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { EButtonColors } from "shared/components/Buttons/interfaces";
import { ModalControlsHeader } from "shared/components/modals/components/ModalControlsHeader";
import { ModalDescription } from "shared/components/modals/components/ModalDescription";
import ModalWrapper from "shared/components/modals/ModalWrapper";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { IRevokeModalProps } from "shared/interfaces";
import { revokeToken } from "store/actions/revokeToken";

export const RevokeModal: React.FC<IRevokeModalProps> = ({ closeModal, name }: IRevokeModalProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const revoke = () => {
    console.log("Revoke in modal");
    dispatch(revokeToken({ name, setLoading, closeHandler: closeModal }));
  };

  return (
    <ModalWrapper closeModal={closeModal}>
      <ModalControlsHeader
        smallIndent
        title={t("modals.revoke.title")}
        rightControl={{ icon: <CloseIcon />, handler: closeModal }}
      />
      <ModalDescription text={t("modals.revoke.description", { name: name })} />
      <AlertMessageBlock text={t("modals.revoke.alertMessage")} />
      <ButtonPrimary
        name={t("actions.revoke")}
        handler={revoke}
        fullWidth
        disabled={loading}
        color={EButtonColors.RED}
      />
    </ModalWrapper>
  );
};
