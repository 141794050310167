import styled from "styled-components";

import { flexRowNoWrap, hoverTransition } from "shared/theme/styles";

import { getButtonStatusColors } from "./helpers";
import { EButtonColors } from "./interfaces";

const ButtonPrimaryWrapper = styled.button<{
  isEmpty: boolean;
  fullWidth: boolean;
  isCentered: boolean;
  color: EButtonColors;
}>`
  ${flexRowNoWrap}
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 700;
  line-height: 140%;
  font-style: normal;
  font-size: 0.75rem;
  align-items: center;
  border-radius: 0.5rem;
  justify-content: center;
  gap: ${({ isEmpty }) => (isEmpty ? "0.625rem" : "0.25rem")};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  margin: ${({ isCentered }) => (isCentered ? "0 auto" : "unset")};
  padding: ${({ isEmpty }) => (isEmpty ? "0" : "0.625rem 0.5rem")};
  background: ${({ color, isEmpty }) => (isEmpty ? "transparent" : getButtonStatusColors(color).default)};
  color: ${({ color, isEmpty }) =>
    isEmpty ? getButtonStatusColors(color).default : getButtonStatusColors(color).text};
  transition: ${hoverTransition};

  &:hover {
    opacity: 0.9;
    color: ${({ color, isEmpty }) =>
      isEmpty ? getButtonStatusColors(color).hover : getButtonStatusColors(color).text};
    background: ${({ color, isEmpty }) => (isEmpty ? "transparent" : getButtonStatusColors(color).hover)};

    svg {
      path {
        fill: ${({ color, isEmpty }) =>
          isEmpty ? getButtonStatusColors(color).hover : getButtonStatusColors(color).text};
      }
    }
  }

  &:active {
    color: ${({ color, isEmpty }) =>
      isEmpty ? getButtonStatusColors(color).active : getButtonStatusColors(color).text};
    background: ${({ color, isEmpty }) => (isEmpty ? "transparent" : getButtonStatusColors(color).active)};

    svg {
      path {
        fill: ${({ color, isEmpty }) =>
          isEmpty ? getButtonStatusColors(color).active : getButtonStatusColors(color).text};
      }
    }
  }

  &:disabled {
    opacity: 0.4;
    background: ${({ color, isEmpty }) => (isEmpty ? "transparent" : getButtonStatusColors(color).default)};
    cursor: not-allowed;
  }
`;

const ButtonIconWrapper = styled.span`
  line-height: 0;
  cursor: pointer;
  display: inline-block;

  svg {
    path {
      transition: ${hoverTransition};
    }
  }

  &:hover {
    svg {
      path {
        opacity: 0.6;
      }
    }
  }
`;

export default {
  ButtonPrimaryWrapper,
  ButtonIconWrapper,
};
