import styled from "styled-components";

import { hoverTransition } from "shared/theme/styles";

const FooterWrapper = styled.footer`
  display: flex;
  gap: 1.415rem;
  flex-direction: column;
  padding: 0.8125rem 1.5rem 1.5rem 1.5rem;
  background-color: ${({ theme }) => theme.colors.black};
`;

const LogoCopyright = styled.div`
  display: flex;
  width: 63.5rem;
  margin: 0 auto;
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Copyright = styled.div`
  font-weight: 400;
  line-height: 140%;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.greyLight};
`;

const TermsAndPrivacy = styled.div`
  gap: 0.5rem;
  display: flex;
  width: 63.5rem;
  margin: 0 auto;
  max-width: 100%;
  align-items: center;
  justify-content: center;

  a {
    gap: 0.5rem;
    display: flex;
    font-weight: 400;
    line-height: 140%;
    font-size: 0.75rem;
    text-align: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.greyLight};
    transition: ${hoverTransition};

    &:after {
      content: "";
      width: 1px;
      height: 1rem;
      display: block;
      background-color: ${({ theme }) => theme.colors.greyLight};
    }

    &:last-child {
      &:after {
        content: none;
      }
    }

    &:hover {
      opacity: 0.9;
    }
  }
`;

export default {
  FooterWrapper,
  LogoCopyright,
  Copyright,
  TermsAndPrivacy,
};
