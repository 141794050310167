import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { AuthService } from "services/api/auth";
import ToastService from "shared/components/Toast";
import { accountLocalStorage } from "shared/constants";
import { EActionTypes, serverErrorHandler } from "shared/helpers/serverErrorHandler";
import { setAccountInitialData } from "store/slices/account";

export const signOut = createAsyncThunk("account/signOut", async (_, { dispatch }) => {
  try {
    const { data } = await AuthService.signOut();
    const { success } = data;

    if (!success) return;

    localStorage.removeItem(accountLocalStorage);
    dispatch(setAccountInitialData());
  } catch (error) {
    if (error instanceof AxiosError) {
      ToastService.error({ text: serverErrorHandler(EActionTypes.SIGN_OUT, error?.response?.data.code) });
    }
    console.error("Error while signing out: ", error);
  }
});
