import { createAsyncThunk } from "@reduxjs/toolkit";
import { t } from "i18next";
import { Dispatch, SetStateAction } from "react";

import { AuthService } from "services/api/auth";
import ToastService from "shared/components/Toast";
import { RootState } from "store";
import { EModals, showModal } from "store/slices/modals";

export const requestResetPassword = createAsyncThunk<
  void,
  {
    email: string;
    setLoading: Dispatch<SetStateAction<boolean>>;
    closeHandler: () => void;
    reset: () => void;
  },
  { state: RootState }
>("account/requestResetPassword", async ({ email, setLoading, closeHandler, reset }, { dispatch }) => {
  try {
    setLoading(true);
    const { data } = await AuthService.requestPasswordReset(email);
    if (!data) return;

    closeHandler();
    dispatch(
      showModal({
        modal: EModals.MESSAGE_MODAL,
        props: {
          title: t("modals.resetPassword.title"),
          description: t("modals.resetPassword.notificationModal.description", { email }),
        },
      })
    );

    reset();
  } catch (error) {
    ToastService.error({ text: t("toast.errors.somethingWentWrong") });
    console.error("Error during password reset request: ", error);
  } finally {
    setLoading(false);
  }
});
