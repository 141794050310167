import styled from "styled-components";

const TariffsWrapper = styled.div`
  gap: 1rem;
  display: flex;
  width: 63.5rem;
  margin: 0 auto;
  max-width: 100%;
  justify-content: center;
  ${({ theme }) => theme.mediaWidth("upToMedium", `flex-direction: column;`)};
`;

const TariffBlock = styled.div`
  width: 33.3%;
  display: flex;
  padding: 1.5rem;
  text-align: center;
  border-radius: 1.5rem;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.yellowLight};
  ${({ theme }) => theme.mediaWidth("upToMedium", `width: 100%;`)};
`;

const TariffName = styled.div`
  font-weight: 700;
  line-height: 130%;
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
`;

const TariffDescription = styled.div`
  font-weight: 400;
  line-height: 140%;
  font-size: 0.875rem;
  margin-bottom: 2.4375rem;
  color: ${({ theme }) => theme.colors.greyDark};
`;

const TitleDescription = styled.div`
  margin-bottom: 2rem;
`;

const Title = styled.div`
  font-weight: 500;
  line-height: 140%;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.greyDark};
`;

const Description = styled.div`
  font-weight: 700;
  line-height: 140%;
  font-size: 1.25rem;
`;

const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: end;
  margin-top: 0.4375rem;
`;

const TitleEnterprise = styled.div`
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
  line-height: 140%;
  margin: 2.1875rem 0 1.5rem 0;
`;

const DescriptionEnterprise = styled(TariffDescription)`
  text-align: left;
`;

export default {
  TariffsWrapper,
  TariffBlock,
  TariffName,
  TariffDescription,
  TitleDescription,
  Title,
  Description,
  ButtonWrapper,
  TitleEnterprise,
  DescriptionEnterprise,
};
