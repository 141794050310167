import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import { APP_ROUTES } from "routes/appRoutes";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import {
  callButtonHandler,
  getAuthVariant,
  getSchemaAndDefaultValues,
} from "shared/components/modals/AuthModal/helpers";
import { ModalControlsHeader } from "shared/components/modals/components/ModalControlsHeader";
import ModalWrapper from "shared/components/modals/ModalWrapper";
import { EMPTY_STRING } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { IAuthModalProps } from "shared/interfaces";

export const AuthModal: React.FC<IAuthModalProps> = ({ closeModal, modal, widthInRem }: IAuthModalProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const code = location.pathname.split("/").pop();

  const [loading, setLoading] = useState<boolean>(false);
  const [apiToken, setApiToken] = useState<string>(EMPTY_STRING);

  const { validationSchema, defaultValues, modalName, buttonName } = getSchemaAndDefaultValues(modal, apiToken);

  type FormDataType = typeof defaultValues;

  const methods = useForm<FormDataType>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: zodResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = methods;

  const authVariant = getAuthVariant(modal, errors, apiToken);

  const closeHandler = () => {
    navigate(APP_ROUTES.HOME);
    closeModal();
  };

  const onSubmit: SubmitHandler<FormDataType> = (data) => {
    callButtonHandler(modal, data, setLoading, closeHandler, reset, dispatch, code, setApiToken, apiToken);
  };

  return (
    <ModalWrapper closeModal={closeHandler} widthInRem={widthInRem}>
      <ModalControlsHeader title={modalName} rightControl={{ icon: <CloseIcon />, handler: closeHandler }} />
      <FormProvider {...methods}>
        <form onSubmit={(e) => e.preventDefault()}>
          {authVariant && authVariant}
          <ButtonPrimary name={buttonName} handler={handleSubmit(onSubmit)} disabled={!isValid || loading} fullWidth />
        </form>
      </FormProvider>
    </ModalWrapper>
  );
};
