import { t } from "i18next";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { AuthService } from "services/api/auth";
import { EMPTY_STRING } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { EModals, showModal } from "store/slices/modals";

export const RegistrationConfirmationRouteWrapper: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const code = location.pathname.split("/").pop();

  const [loading, setLoading] = useState<boolean>(true);
  const [modalData, setModalData] = useState<{ title: string; description: string }>({
    title: t("modals.signUpConfirmation.processing"),
    description: EMPTY_STRING,
  });

  const confirmAccount = async (code: string) => {
    try {
      const success = await AuthService.confirmAccount(code);

      if (!success) {
        setModalData({
          title: t("modals.signUpConfirmation.fail.title"),
          description: t("modals.signUpConfirmation.fail.description"),
        });
        return;
      }

      setModalData({
        title: t("modals.signUpConfirmation.success.title"),
        description: t("modals.signUpConfirmation.success.description"),
      });
    } catch (error) {
      setModalData({
        title: t("modals.signUpConfirmation.fail.title"),
        description: t("modals.signUpConfirmation.fail.description"),
      });
      console.error("Error during account confirmation: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!code || !code.length) return;

    dispatch(
      showModal({
        modal: EModals.MESSAGE_MODAL,
        props: { title: modalData.title, description: modalData.description, loading },
      })
    );

    if (!loading) return;
    confirmAccount(code);
  }, [code, loading, modalData]);

  return <></>;
};
