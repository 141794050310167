import { z as zod } from "zod";

import i18n from "services/translation";
import { EMPTY_STRING } from "shared/constants";

export const signUpValidationSchema = zod.object({
  name: zod.string().min(1, i18n.t("modals.common.inputs.messages.requiredField") || EMPTY_STRING),
  email: zod
    .string()
    .min(1, i18n.t("modals.common.inputs.messages.requiredField") || EMPTY_STRING)
    .email({ message: i18n.t("modals.common.inputs.messages.incorrectValue") }),
  password: zod.string().min(6, i18n.t("modals.common.inputs.messages.passwordLength") || EMPTY_STRING),
  agreement: zod.literal(true),
});

export const signInValidationSchema = zod.object({
  email: zod
    .string()
    .min(1, i18n.t("modals.common.inputs.messages.requiredField") || EMPTY_STRING)
    .email({ message: i18n.t("modals.common.inputs.messages.incorrectValue") }),
  password: zod.string().min(6, i18n.t("modals.common.inputs.messages.passwordLength") || EMPTY_STRING),
});

export const resetPasswordValidationSchema = zod.object({
  password: zod.string().min(6, i18n.t("modals.common.inputs.messages.passwordLength") || EMPTY_STRING),
});

export const forgotPasswordValidationSchema = zod.object({
  email: zod
    .string()
    .min(1, i18n.t("modals.common.inputs.messages.requiredField") || EMPTY_STRING)
    .email({ message: i18n.t("modals.common.inputs.messages.incorrectValue") }),
});

export const createTokenValidationSchema = zod.object({
  name: zod.string().min(3, i18n.t("modals.common.inputs.messages.requiredField") || EMPTY_STRING),
});
